import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let Confirmation = class Confirmation extends Vue {
};
Confirmation = __decorate([
    Component({
        components: {},
    })
], Confirmation);
export default Confirmation;
